import Login from '../Components/Login';
import FacilityLocations from '../Components/FacilityLocations';
import FacilityCampus from '../Components/FacilityCampus';
// import KUColleges from '../Components/KUColleges';
import Settings from '../Components/Settings';
import Links from '../Components/Links'
import Banners from '../Components/Banners';

// assets
import Email from '../Assets/Email';
import Phone from '../Assets/Phone';
import Zones from '../Components/Attendance/Zones';
import LatitudeIcon from '../Assets/Latitude';
import LongitudeIcon from '../Assets/Longitude';
import RangeIcon from '../Assets/Range';
import Approval from '../Components/Attendance/Approvals/approvalbase';


export const LANGUAGE = {
    EN: 'en',
    AR: 'ar',
}

export const DIRECTION = {
    LTR: 'ltr',
    RTL: 'rtl',
}


export const ROUTE = {
    LOGIN: {
        PATH: '/login',
        PROTECTED: false,
        COMPONENT: Login,
    },
    BANNERS: {
        PATH: '/banners',
        PROTECTED: true,
        COMPONENT: Banners,
    },
    ATTENDANCE: {
        PATH: '/attendance',
        PROTECTED: true,
       
    },
    ZONE: {
        PATH: '/attendance/zone',
        PROTECTED: true,
        COMPONENT: Zones,
       
    },
    APPROVAL: {
        PATH: '/attendance/approval',
        PROTECTED: true,
        COMPONENT: Approval,
       
    },
    FACILITY_CAMPUS: {
        PATH: '/facility-locations/',
        PROTECTED: true,
        COMPONENT: FacilityCampus,
    },
    FACILITY_LOCATIONS: {
        PATH: '/facility-locations/:campusId',
        PROTECTED: true,
        COMPONENT: FacilityLocations,
    },
    /* not required
    KU_COLLEGES: {
        PATH: '/ku-colleges',
        PROTECTED: true,
        COMPONENT: KUColleges,
    }, */
    SETTINGS: {
        PATH: '/settings',
        PROTECTED: true,
        COMPONENT: Settings,
    },
    LINKS: {
        PATH: '/commonlinks',
        PROTECTED: true,
        COMPONENT: Links,
    },
};

export const HEADER_LINKS = [
    {
        TEXT: 'header.banners',
        ROUTE_OBJECT: ROUTE.BANNERS,
    },
    {
        TEXT: 'header.attendance',
        ROUTE_OBJECT: ROUTE.ATTENDANCE,
        SUBMENU: [
            {
                TEXT: 'header.zone',  // i18n key for Zone
                PATH: ROUTE.ZONE.PATH,
            },
            {
                TEXT: 'header.approvals',  // Example for another submenu item
                PATH: ROUTE.APPROVAL.PATH,  // Adjust this path according to your application
            },
        ],
    },
    {
        TEXT: 'header.facilityLocations',
        ROUTE_OBJECT: ROUTE.FACILITY_CAMPUS,
    },
    {
        TEXT: 'header.commonlinks',
        ROUTE_OBJECT: ROUTE.LINKS,
    },
    /* not required
    {
        TEXT: 'header.kuColleges',
        ROUTE_OBJECT: ROUTE.KU_COLLEGES,
    }, */
    {
        TEXT: 'header.settings',
        ROUTE_OBJECT: ROUTE.SETTINGS,
    }
]

export const DEFAULT_ROUTE = ROUTE.BANNERS.PATH;

export const PARSING_KEYS = {
    COMMON: {
        NAME_EN: 'nameEn',
        NAME_AR: 'nameAr',
    },
    CAMPUS: {
        NAME_EN: 'campsNameEn',
        NAME_AR: 'campsNameAr',
        EMAIL: 'emailId',
        WHATSAPP_NO: 'whatsAppNo',
        PARSED_WHATS_APP_NO: 'parsedWhatsAppNo',
    },
    BUILDING: {
        NAME_EN: 'bldgNameEn',
        NAME_AR: 'bldgNameAr',
    },
    FLOOR: {
        NAME_EN: 'flrNameEn',
        NAME_AR: 'flrNameAr',
    },
    LOCATION: {
        NAME_EN: 'locNameEn',
        NAME_AR: 'locNameAr',
    },
    SETTINGS: {
        ID: 'configName',
        VALUE: 'value',
    },
    BANNERS: {
        ID: 'configName',
        VALUE: 'value',
    },
    ZONES: {
        NAME_EN: 'zoneName',
        LATITUDE: 'latitude',
        LONGITUDE: 'longitude',
        RANGE: 'range',
    },
}

export const REGEX = {
    EMAIL: '^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,}$',
    MOBILE_NO: '^[0-9]{8}$',
    LINK: '^http(s)?://[^ .]+([.][^ .]+)+$',
    USER_ID: '^[0-9]*$',
    INBETWEEN_SPACES: /[ ]+/g,
    RANGE:'^\\d+$',
    LATITUDE:'^(\\+|-)?(90(\\.0+)?|[1-8]?\\d(\\.\\d+)?)$',
    LONGITUDE:'^(\\+|-)?(180(\\.0+)?|1[0-7]\\d(\\.\\d+)?|[1-9]?\\d(\\.\\d+)?)$'
}

export const BANNERS_URL = [
    {
        [PARSING_KEYS.SETTINGS.ID]: 'KU_EVENTS_IMAGE_URLS',
        //REGEX: '/^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_\+.~#?&\/=]*)$/'
        REGEX: '^https?://[^ ,]+',   
    }
];

export const REPORT_LOST_ITEM =[
    {
        [PARSING_KEYS.SETTINGS.ID]: 'LOST_ITEM_DESCRIPTION_LIMIT',
        REGEX: '^([1-9][0-9]{0,3}|[]?)$',
    },
    {
        [PARSING_KEYS.SETTINGS.ID]: 'LOST_ITEM_NAME_LIMIT',
        REGEX: '^([1-9][0-9]{0,3}|[]?)$',
    },
    {
        [PARSING_KEYS.SETTINGS.ID]: 'LOST_ITEM_EMAIL_ID',
        REGEX: '^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,}$',
        // key: PARSING_KEYS.SETTINGS.ID.LOST_ITEM_EMAIL_ID,
        // label: 'settings.LOST_ITEM_EMAIL_ID.text',
        // className: 'title',
        // value: '',
        // md: 6,
        // maxCharLimit: 50,
        // isEditable: true,
        // placeholder: 'placeholder.email',
        // tooltip: true,
    },
    {
        [PARSING_KEYS.SETTINGS.ID]: 'LOST_ITEM_PHONE_NUMBER',
        REGEX: '^[1-9][0-9]{0,10}|[]?$',
    },
];

export const ATTENDANCE_SETTINGS=[
    {
        [PARSING_KEYS.SETTINGS.ID]: 'ENABLE_DEVICE_BIOMETRICS',
    },
    {
        [PARSING_KEYS.SETTINGS.ID]: 'ENABLE_FACE_RECOGNITION',
    
    },
    {
        [PARSING_KEYS.SETTINGS.ID]: 'ENABLE_FACE_LIVENESS',
    
    },
    {
        [PARSING_KEYS.SETTINGS.ID]: 'ATTENDANCE_SEND_EMAIL_NOTIFICATION',
    
    },
    {
        [PARSING_KEYS.SETTINGS.ID]: 'ATTENDANCE_SEND_PUSH_NOTIFICATION',
    
    },
    {
        [PARSING_KEYS.SETTINGS.ID]: 'ATTENDANCE_ADMIN_EMAIL_ID',
        REGEX: '^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,}$'


    },
]

export const SECURITY_SERVICES =[
    {
        [PARSING_KEYS.SETTINGS.ID]: 'SS_EMERGENCY_NUMBER',
        REGEX: '^[1-9][0-9]{0,10}|[]?$',
    },
    {
        [PARSING_KEYS.SETTINGS.ID]: 'SS_ROADSIDE_ASSISTANCE_NUMBER',
        REGEX: '^[1-9][0-9]{0,10}|[]?$',
    },
    {
        [PARSING_KEYS.SETTINGS.ID]: 'SS_GENERAL_INQUIRIES_NUMBER',
        REGEX: '^[1-9][0-9]{0,10}|[]?$',

    },
    {
        [PARSING_KEYS.SETTINGS.ID]: 'SS_MEDICAL_CLINICS',
        REGEX: '^[1-9][0-9]{0,10}|[]?$',
    },
    {
        [PARSING_KEYS.SETTINGS.ID]: 'SS_LOST_FOUND_NUMBER',
        REGEX: '^[1-9][0-9]{0,10}|[]?$',
    },
    {
        [PARSING_KEYS.SETTINGS.ID]: 'SS_UNIVERSITY_ID',
        REGEX: '^[1-9][0-9]{0,10}|[]?$',
    },
];

export const DATA_UPDATE_LIST =[
    {
        [PARSING_KEYS.SETTINGS.ID]: 'DATA_UPDATE_COMMENTS_LIMIT',
        REGEX: '^([1-9][0-9]{0,3}|[]?)$',
    },
    {
        [PARSING_KEYS.SETTINGS.ID]: 'DATA_UPDATE_ATTACHMENT_MIN_COUNT',
        REGEX: '^([1-9][0-9]{0,1}|[0]?)$',
        COMPARE_WITH: 'DATA_UPDATE_ATTACHMENT_MAX_COUNT',
        COMPARE_FUNCTION: (value, compareValue) => {
            return value <= compareValue;
        },
    },
    {
        [PARSING_KEYS.SETTINGS.ID]: 'DATA_UPDATE_ATTACHMENT_MAX_COUNT',
        REGEX: '^([1-9][0-9]{0,1}|[]?)$',
        COMPARE_WITH: 'DATA_UPDATE_ATTACHMENT_MIN_COUNT',
        COMPARE_FUNCTION: (value, compareValue) => {
            return value >= compareValue;
        },
    },
    {
        [PARSING_KEYS.SETTINGS.ID]: 'DATA_UPDATE_PER_ATTACHMENT_SIZE',
        REGEX: '^([1-9][0-9]{0,1}|[]?)$',
        COMPARE_WITH: 'DATA_UPDATE_ATTACHMENT_SIZE',
        COMPARE_FUNCTION: (value, compareValue) => {
            return value <= compareValue;
        },
    },
    {
        [PARSING_KEYS.SETTINGS.ID]: 'DATA_UPDATE_ATTACHMENT_SIZE',
        REGEX: '^([1-9][0-9]{0,1}|[]?)$',
        COMPARE_WITH: 'DATA_UPDATE_PER_ATTACHMENT_SIZE',
        COMPARE_FUNCTION: (value, compareValue) => {
            return value >= compareValue;
        },
    },
    {
        [PARSING_KEYS.SETTINGS.ID]: 'KU_APP_EMAIL_ID_TO',
        REGEX: '^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,}$',
    },
];

export const SETTINGS_FEEDBACK_CONFIG_LIST =[
    {
        [PARSING_KEYS.SETTINGS.ID]: 'FACILITY_FEEDBACK_PER_ATTACHMENT_SIZE',
        REGEX: '^([1-9][0-9]{0,1}|[]?)$',
        COMPARE_WITH: 'FACILITY_FEEDBACK_ATTACHMENT_SIZE',
        COMPARE_FUNCTION: (value, compareValue) => {
            return value <= compareValue;
        },
    },
    {
        [PARSING_KEYS.SETTINGS.ID]: 'FACILITY_FEEDBACK_ATTACHMENT_SIZE',
        REGEX: '^([1-9][0-9]{0,1}|[]?)$',
        COMPARE_WITH: 'FACILITY_FEEDBACK_PER_ATTACHMENT_SIZE',
        COMPARE_FUNCTION: (value, compareValue) => {
            return value >= compareValue;
        },
    },
    {
        [PARSING_KEYS.SETTINGS.ID]: 'FACILITY_FEEDBACK_ATTACHMENT_MIN_COUNT',
        REGEX: '^([1-9][0-9]{0,1}|[0]?)$',
        COMPARE_WITH: 'FACILITY_FEEDBACK_ATTACHMENT_MAX_COUNT',
        COMPARE_FUNCTION: (value, compareValue) => {
            return value <= compareValue;
        },
    },
    {
        [PARSING_KEYS.SETTINGS.ID]: 'FACILITY_FEEDBACK_ATTACHMENT_MAX_COUNT',
        REGEX: '^([1-9][0-9]{0,1}|[]?)$',
        COMPARE_WITH: 'FACILITY_FEEDBACK_ATTACHMENT_MIN_COUNT',
        COMPARE_FUNCTION: (value, compareValue) => {
            return value >= compareValue;
        },
    },
    {
        [PARSING_KEYS.SETTINGS.ID]: 'FACILITY_FEEDBACK_CHAR_LIMIT',
        REGEX: '^([1-9][0-9]{0,3}|[]?)$',
    },
];

export const SETTINGS_EVENT_CONFIG_LIST = [
    {
        [PARSING_KEYS.SETTINGS.ID]: 'MSOFFICE365_MAX_RECORD_COUNT',
        REGEX: '^([1-9][0-9]{0,2}|1000|[]?)$',
    },
    {
        [PARSING_KEYS.SETTINGS.ID]: 'MY_CALENDAR_SYNCED_EVENTS_HISTORY_FOR_MONTHS',
        REGEX: '^([1-9][0-9]{0,3}|[]?)$',
    },
    {
        [PARSING_KEYS.SETTINGS.ID]: 'EVENTS_FETCH_DAYS',
        REGEX: '^([1-9][0-9]{0,3}|[]?)$',
        COMPARE_WITH: 'EVENTS_FETCH_LIMIT_IN_DAYS',
        COMPARE_FUNCTION: (value, compareValue) => {
            return value <= compareValue;
        },
    },
    {
        [PARSING_KEYS.SETTINGS.ID]: 'EVENTS_FETCH_LIMIT_IN_DAYS',
        REGEX: '^([1-9][0-9]{0,3}|[]?)$',
        COMPARE_WITH: 'EVENTS_FETCH_DAYS',
        COMPARE_FUNCTION: (value, compareValue) => {
            return value >= compareValue;
        },
    },
];

export const SETTINGS_CONFIG_LIST = [
    {
        [PARSING_KEYS.SETTINGS.ID]: 'FACILITY_FEEDBACK_PER_ATTACHMENT_SIZE',
        REGEX: '^([1-9][0-9]{0,1}|[]?)$',
        COMPARE_WITH: 'FACILITY_FEEDBACK_ATTACHMENT_SIZE',
        COMPARE_FUNCTION: (value, compareValue) => {
            return value <= compareValue;
        },
    },
    {
        [PARSING_KEYS.SETTINGS.ID]: 'FACILITY_FEEDBACK_ATTACHMENT_SIZE',
        REGEX: '^([1-9][0-9]{0,1}|[]?)$',
        COMPARE_WITH: 'FACILITY_FEEDBACK_PER_ATTACHMENT_SIZE',
        COMPARE_FUNCTION: (value, compareValue) => {
            return value >= compareValue;
        },
    },
    {
        [PARSING_KEYS.SETTINGS.ID]: 'FACILITY_FEEDBACK_ATTACHMENT_MIN_COUNT',
        REGEX: '^([1-9][0-9]{0,1}|[0]?)$',
        COMPARE_WITH: 'FACILITY_FEEDBACK_ATTACHMENT_MAX_COUNT',
        COMPARE_FUNCTION: (value, compareValue) => {
            return value <= compareValue;
        },
    },
    {
        [PARSING_KEYS.SETTINGS.ID]: 'FACILITY_FEEDBACK_ATTACHMENT_MAX_COUNT',
        REGEX: '^([1-9][0-9]{0,1}|[]?)$',
        COMPARE_WITH: 'FACILITY_FEEDBACK_ATTACHMENT_MIN_COUNT',
        COMPARE_FUNCTION: (value, compareValue) => {
            return value >= compareValue;
        },
    },
    {
        [PARSING_KEYS.SETTINGS.ID]: 'FACILITY_FEEDBACK_CHAR_LIMIT',
        REGEX: '^([1-9][0-9]{0,3}|[]?)$',
    },
    {
        [PARSING_KEYS.SETTINGS.ID]: 'MSOFFICE365_MAX_RECORD_COUNT',
        REGEX: '^([1-9][0-9]{0,2}|1000|[]?)$',
    },
    {
        [PARSING_KEYS.SETTINGS.ID]: 'MY_CALENDAR_SYNCED_EVENTS_HISTORY_FOR_MONTHS',
        REGEX: '^([1-9][0-9]{0,3}|[]?)$',
    },
    {
        [PARSING_KEYS.SETTINGS.ID]: 'EVENTS_FETCH_DAYS',
        REGEX: '^([1-9][0-9]{0,3}|[]?)$',
        COMPARE_WITH: 'EVENTS_FETCH_LIMIT_IN_DAYS',
        COMPARE_FUNCTION: (value, compareValue) => {
            return value <= compareValue;
        },
    },
    {
        [PARSING_KEYS.SETTINGS.ID]: 'EVENTS_FETCH_LIMIT_IN_DAYS',
        REGEX: '^([1-9][0-9]{0,3}|[]?)$',
        COMPARE_WITH: 'EVENTS_FETCH_DAYS',
        COMPARE_FUNCTION: (value, compareValue) => {
            return value >= compareValue;
        },
    },
]; 

export const COUNTRY_CODE = '965';

export const CAMPUS_METADATA = [
    {
        key: PARSING_KEYS.CAMPUS.NAME_EN,
        label: 'modal.addCampus.nameEN',
        className: 'title',
        value: '',
        md: 12,
        maxCharLimit: 100,
        isEditable: true,
        placeholder: 'placeholder.campusNameEn',
        tooltip: true,
    },
    {
        key: PARSING_KEYS.CAMPUS.NAME_AR,
        label: 'modal.addCampus.nameAR',
        className: 'title GeezaProRegular',
        value: '',
        md: 12,
        maxCharLimit: 100,
        isEditable: true,
        placeholder: 'placeholder.campusNameAr',
        tooltip: true,
    },
    {
        key: PARSING_KEYS.CAMPUS.EMAIL,
        label: 'modal.addCampus.email',
        className: 'data',
        value: '',
        md: 12,
        maxCharLimit: 50,
        icon: Email,
        isEditable: true,
        regex: REGEX.EMAIL,
        placeholder: 'placeholder.email',
        tooltip: true,
    },
    {
        key: PARSING_KEYS.CAMPUS.WHATSAPP_NO,
        className: 'data',
        value: '',
        md: 12,
        maxCharLimit: 20,
        icon: Phone,
        isEditable: false,
        placeholder: 'placeholder.number',
    },
    {
        key: PARSING_KEYS.CAMPUS.PARSED_WHATSAPP_NO,
        label: 'modal.addCampus.whatsappNo',
        className: 'data whats-app-no',
        value: '',
        md: 12,
        maxCharLimit: 20,
        icon: Phone,
        isEditable: true,
        regex: REGEX.MOBILE_NO,
        placeholder: 'placeholder.number',
        isVisible: false,
        prepend: COUNTRY_CODE,
    },
];

export const ZONE_METADATA = [
    {
        key: PARSING_KEYS.ZONES.NAME_EN,
        label: 'modal.addzone.nameEN',
        className: 'title',
        value: '',
        md: 12,
        maxCharLimit: 100,
        isEditable: false,
        placeholder: 'placeholder.zoneName',
        tooltip: true,
        add:true
    },
    {
        key: PARSING_KEYS.ZONES.LATITUDE,
        label: 'modal.addzone.latitude',
        icon:LatitudeIcon,
        className: 'data',
        value: '',
        md: 12,
        maxCharLimit: 50,
        isEditable: true,
        placeholder: 'placeholder.latitude',
        tooltip: true,
        add:true,
        regex: REGEX.LATITUDE,
    },
    {
        key: PARSING_KEYS.ZONES.LONGITUDE,
        label: 'modal.addzone.longitude',
        icon:LongitudeIcon,
        className: 'data',
        value: '',
        md: 12,
        maxCharLimit: 20,
        isEditable: true,
        placeholder: 'placeholder.longitude',
        add:true,
        regex: REGEX.LONGITUDE,
    },
    {
        key: PARSING_KEYS.ZONES.RANGE,
        label: 'modal.addzone.range',
        icon:RangeIcon,
        className: 'data',
        value: '',
        md: 12,
        maxCharLimit: 20,
        isEditable: true,
        placeholder: 'placeholder.range',
        add:true,
        regex: REGEX.RANGE,
        
        
    },
];

export const ENTER_CHAR_CODE = 13;

export const CRITICAL_ERROR_CODES = [5002];

export const TIMEOUT_DURATION = 30000;


export const APPROVAL_HEAD = [
    {
        TEXT: 'header.pending',
        activeTab:"PENDING"
    },
    {
        TEXT: 'header.approved',
        activeTab:"APPROVED"

    },
    {
        TEXT: 'header.rejected',
        activeTab:"REJECTED"
    }
]