import React from 'react';
import { Container, Nav, NavItem, NavLink, Spinner } from 'reactstrap';
import classnames from 'classnames';
import ApprovalScreen from './ApprovalComponent';
import './Approval.scss';
import { APPROVAL_HEAD } from '../../../Constants/AppConstants';
import { withNamespaces } from 'react-i18next';
import { URLS } from '../../../HOC/Communication/ApiUrls';
import { axiosInstance } from '../../../HOC/Communication';
import { MODAL_SIZE, withModal } from '../../../HOC/Modal';
import PopupModal from '../../Common/PopupModal/PopupModal';

class ApprovalBaseComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: APPROVAL_HEAD[0].activeTab,
      list: null,
      pageInfo: null,
      pageSize: 0,
      size: 10,
      noData: false,
      isLoading: true, // Track loading state
    };
  }

  componentDidMount() {
    this.axios = axiosInstance;
    this.getApprovals(this.state.activeTab, this.state.pageSize, this.state.size);
    this.props.handelBackButton();
  }

  getApprovals = (status = this.state.activeTab, page = this.state.pageSize, size = this.state.size) => {
    this.setState({ noData: false, isLoading: true }); // Set isLoading to true when fetching data
    this.props.showModal(<Spinner />);

    this.axios
      .get(URLS.GET_APPROVALS + '?status=' + status + '&page=' + page + '&size=' + size)
      .then((res) => {
        this.props.closeModal();
        this.setState({ isLoading: false }); // Set isLoading to false after fetching data

        if (!res.isError) {
          const list = res.data.data.data;
          const pageInfo = res.data.data;
          this.setState({ list, pageInfo, noData: list.length === 0 }); // Update noData based on list length
        } else {
          this.setState({ list: [], noData: true });
        }
      })
  };

  handlePageChange = (newPage) => {
    this.setState({ pageSize: newPage - 1 }, () => {
      this.getApprovals(this.state.activeTab, this.state.pageSize, this.state.size);
    });
  };

  editStatus = (id, status, remark) => {
    this.props.showModal(<Spinner />);
    const data = {
      status: status,
      remarks: remark,
    };
    this.axios
      .put(URLS.GET_APPROVALS + id + '/status', {
        ...data,
      })
      .then((res) => {
        this.props.closeModal();
        if (!res.isError) {
          setTimeout(() => {
          this.props.showModal(
            <PopupModal
              title="modal.success"
              message="modal.successMessage.statusUpdation"
              onOkClick={() => this.getApprovals()}
            />,
            MODAL_SIZE.SM
          );
        },200)
        }
      });
  };

  toggleTab = (tab) => {
    if (this.state.activeTab !== tab) {
      this.setState({ activeTab: tab });
      this.getApprovals(tab, 0, this.state.size);
    }
  };

  renderApprovalTab = () => {
    return APPROVAL_HEAD.map((element, index) => (
      <NavItem key={index}>
        <NavLink
          className={classnames({ active: this.state.activeTab === element.activeTab })}
          onClick={() => this.toggleTab(element.activeTab)}
        >
          {this.props.i18n.t(element.TEXT)}
        </NavLink>
      </NavItem>
    ));
  };

  render() {
    return (
      <div className="base-container my-3">
        <div className="base-component">
          <Nav tabs className="mb-3">
            {this.renderApprovalTab()}
          </Nav>
          {!this.state.isLoading && Array.isArray(this.state.list) && this.state.list.length !== 0 ? (
            <Container fluid>
              <ApprovalScreen
                activeTab={this.state.activeTab}
                list={this.state.list}
                pageInfo={this.state.pageInfo}
                handlePageChange={this.handlePageChange}
                editStatus={this.editStatus}
                showModal={this.props.showModal}
                closeModal={this.props.closeModal}
              />
            </Container>
          ) : (
            !this.state.isLoading && ( // Only show no data message when not loading
              <div className="nodata">
                {this.state.noData &&
                  (this.state.activeTab === APPROVAL_HEAD[0].activeTab
                    ? this.props.i18n.t('common.noPending')
                    : this.state.activeTab === APPROVAL_HEAD[1].activeTab
                    ? this.props.i18n.t('common.noApproval')
                    : this.props.i18n.t('common.noRejected'))}
              </div>
            )
          )}
        </div>
      </div>
    );
  }
}

export default withNamespaces()(withModal(ApprovalBaseComponent));
